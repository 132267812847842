.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f8f9fa;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  color: black;
  text-decoration: none;
}

.p-menubar {
  background-color: white;
  border: none;
  border-radius: 0%;
  box-shadow: 0 4px 2px -2px rgba(85, 85, 85, 0.21);
}

.p-breadcrumb {
  background-color: #61dafb00;
  border: none;
}

.p-card {
  margin: 5px 15px 0 15px;
  margin-bottom: 20px;
}

.p-card-content {
  padding: 0 !important;
}

.input-active {
  margin-bottom: 37px;
}

.p-card .p-card-body {
  padding: 20px !important;
}

.paginatorArrow {
  padding: 10px 20px 0 20px;
}

.paginatorResult {
  padding: 0 20px 10px 20px;
}

.p-dialog-header {
  padding: 0.2em !important;
}
